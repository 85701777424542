import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Navbar from "root/components/Navbar";
import Hero from "root/sections/SolutionHero";
import SolutionValuePropositions from "root/sections/SolutionValuePropositions";
import SolutionTestimonials from "root/sections/Testimonials";
import SolutionInstitutions from "root/sections/SolutionInstitutions";
import SolutionKnowMore from "root/sections/SolutionKnowMore";
import Footer from "root/components/Footer";
import Apoios from "root/components/Apoios";
import { useTranslation } from "react-i18next";
import ShuffleArray from "../../components/ShuffleArray";

/* eslint-disable id-length */
const SolutionCitiesPage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("pt-PT");
  }, []);

  const testimonials = ShuffleArray(t("testimonials").testimonials);

  const data = useStaticQuery(graphql`
    query {
      values1: file(relativePath: { eq: "valuesCities1.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      values1_mobile: file(relativePath: { eq: "valuesCities1_m.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      specifications1: file(relativePath: { eq: "specifications-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      specifications2: file(relativePath: { eq: "home-values1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testimonial: file(relativePath: { eq: "testimonial.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knowMore: file(relativePath: { eq: "knowMore.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      iconTv: file(relativePath: { eq: "icon_tv.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconReligion: file(relativePath: { eq: "icon_religion.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconPhoto: file(relativePath: { eq: "icon_photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconNews: file(relativePath: { eq: "icon_news.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconMusic: file(relativePath: { eq: "icon_music.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconGames: file(relativePath: { eq: "icon_games.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconDraw: file(relativePath: { eq: "icon_draw.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconPhone: file(relativePath: { eq: "icon_phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hero: file(relativePath: { eq: "solutionsCityHero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wheelChairDT: file(relativePath: { eq: "wheelChair DT.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      professionals: file(relativePath: { eq: "professionalsCities.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      values3: file(relativePath: { eq: "valuesCities3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroMobile: file(relativePath: { eq: "solutionsCityHero_mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knowMoreGrid: file(relativePath: { eq: "knowMoreGrid.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const valuePropositionImages = {
    professionals: data.professionals,
    values3: data.values3,
    wheelChairDT: data.wheelChairDT,
    iconGames: data.iconGames,
    iconDraw: data.iconDraw,
    iconMusic: data.iconMusic,
    iconNews: data.iconNews,
    iconPhone: data.iconPhone,
    iconPhoto: data.iconPhoto,
    iconReligion: data.iconReligion,
    iconTv: data.iconTv,
    values1: data.values1,
    values1_m: data.values1_mobile,
  };

  return (
    <div>
      <SEO title={t("pages.solutionCities.label")} keywords="" />
      <Layout>
        <Navbar
          color="blue"
          currentPage="solutionCities"
          buttonColor="green"
          blueLogin="true"
        />
        <Hero
          image={data.hero}
          heroStyles={{
            width: { width: "920px" },
            right: { right: "-270px" },
            mobileWidth: { maxWidth: "1024px" },
          }}
          imageMobile={data.heroMobile}
          color="green"
          translations={t("solutionCities.hero")}
        />
        <SolutionValuePropositions
          images={valuePropositionImages}
          translations={t("solutionCities.valuePropositions")}
          color="green"
        />
        <SolutionKnowMore
          translations={t("solutionCities.knowMore")}
          image={data.knowMore}
          color="green"
          knowMoreGrid={data.knowMoreGrid}
        />
        <SolutionTestimonials
          color="green"
          videoUrl="https://www.youtube.com/embed/fLB4i9SpJN8"
          image={data}
          testimonials={testimonials}
        />
        <SolutionInstitutions color="green" />
        <Footer />
        <Apoios />
      </Layout>
    </div>
  );
};

/* eslint-enable id-length */

export default SolutionCitiesPage;
